/*******************************
         Site Overrides
*******************************/

.ui.breadcrumb a {
  color: #ffffff;
}
.ui.breadcrumb a:hover {
  color: #ffffff;
}

/* Divider */
.ui.breadcrumb .divider {
  color: #ffffff;
}
.fpWrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  padding-top: 52px;
}
.sidebar {
  border-right: 0px solid lightgray;
  background-color: #fcfcfc;
}
.fpPanelMain {
  width: 100%;
}
